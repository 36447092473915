import { BlitzPage, Routes } from "@blitzjs/next"
import Layout from "src/core/layouts/Layout"
import { Box, Container } from "@mui/material"

const Home: BlitzPage = () => {
  return (
    <Container maxWidth="xl" sx={{ height: "90vh" }}>
      <Box
        component="iframe"
        width="100%"
        height="100%"
        border="0"
        src="https://outline.aginix.tech/share/cffb4ba9-a027-4d94-8c8a-7b9bd11c8200"
      />
    </Container>
  )
}

Home.authenticate = {
  redirectTo: Routes.LoginPage(),
}
Home.getLayout = (page) => <Layout title="Home">{page}</Layout>

export default Home
